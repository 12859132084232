import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'

import axios from 'axios'

export const LOCAL_STORAGE_KEY_SESSIONKEY = 'sessionKey'

axios.interceptors.request.use((config) => {
  const sessionKey = localStorage.getItem(LOCAL_STORAGE_KEY_SESSIONKEY)
  config.headers['Authorization'] = sessionKey
  return config
})

ReactDOM.render(<App />, document.getElementById('root'))
