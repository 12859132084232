import './styles.scss'

import React from 'react'
import axios from 'axios'
import Toastify from 'toastify-js'

import { withRouter } from 'react-router'
import { LOCAL_STORAGE_KEY_SESSIONKEY } from '@/index'
import Button from '@/components/button'
import Input from '@/components/input'

interface LoginPageState {
  loginForm: {
    username: string
    password: string
  }
}

interface Props {
  match: any
  location: any
  history: any
}

class LoginPage extends React.Component<Props, LoginPageState> {
  state = {
    loginForm: {
      username: '',
      password: ''
    }
  }

  public constructor (props) {
    super(props)
    this.checkAuth()
  }

  private onChange (props: string) {
    return (value) => {
      const { loginForm } = this.state
      this.setState({
        loginForm: {
          ...loginForm,
          [props]: value
        }
      })
    }
  }

  private submitLogin = async (e) => {
    if (e) {
      e.preventDefault()
    }
    const { history, location } = this.props
    try {
      const resp = await axios.post('/api/auth/auth', {
        ...this.state.loginForm
      })
      localStorage.setItem(LOCAL_STORAGE_KEY_SESSIONKEY, resp.data.SessionKey)
      history.push('/token' + location.search)
    } catch (e) {
      Toastify({
        text: 'Login Failed! Please check you username and password',
        duration: 3000,
        close: true,
        gravity: top,
        positionLeft: false,
        backgroundColor: '#fc3c3c',
        stopOnFocus: true
      }).showToast()
    }
  }

  private checkAuth = async () => {
    const { history, location } = this.props
    try {
      await axios.get('/api/auth/auth')
      history.push('/token' + location.search)
    } catch {
      // Empty
    }
  }

  render () {
    const { loginForm } = this.state

    return (
      <div className='container'>
        <div className='login-box'>
          <h1>Codetector 统一登录</h1>
          <h2 className="login-title">登录</h2>
          <form onSubmit={this.submitLogin}>
            <div className="username">
              <Input placeholder="Username" value={loginForm.username} onChange={this.onChange('username')} required/>
            </div>
            <div className="password">
              <Input placeholder="Password" value={loginForm.password} onChange={this.onChange('password')} type="password" required/>
            </div>
            <div className="forgot-password">
              <a>Forgot password</a>
            </div>
            <div className="login-btn">
              <Button type="primary" htmlType="submit">Sign On</Button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default withRouter(LoginPage)
