import './App.scss'
import 'toastify-js/src/toastify.css'

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import React from 'react'

import Login from '@/pages/login'
import TokenPage from '@/pages/token'

function App () {
  return (
    <div className="app-root">
      <BrowserRouter>
        <Switch>
          <Route path="/token" exact component={TokenPage} />
          <Route path="/login" exact component={Login} />
          <Route render={props => (<Redirect to="/login" />) } />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default App
